@import '/theme';

.NavBar {
  display: flex;
  background: $white;
  height: 70px;
  align-items: center;

  padding: 0.5em 1em;
  border-bottom: 1px solid $darkGrey;

  .Logo {
    width: 160px;
    margin-right: 20px;
  }

  @media only screen and (max-width: 768px) {
    .Logo {
      width: 100px;
    }
  }

  span {
    flex-grow: 2;
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 20px;
      text-align: center;
      font-size: 1.3em;
      color: $tertiary_colour;

      &:hover {
        color: $primary_colour;
      }

      &.activeNavButton {
        color: $secondary_colour;
      }

      h3 {
        font-size: 16px;
        margin: 0 0 0 1em;
      }
    }
  }

  .LoggedInUser {
    display: flex;
    align-items: center;

    .Logo {
      width: 160px;
      height: 50px;
      margin-right: 20px;
      background-position: center right;
      background-size: contain;
      background-repeat: no-repeat;

    }

    @media only screen and (max-width: 968px) {
      .Logo {
        display: none;
      }
    }

    .UserAvatar--inner {
      background-color: $primary_colour !important;
      color: $white;
      font-weight: 700;
      font-size: 20px;
    }

    div.LoggedInName {
      padding-left: 0.5em;
      display: flex;
      flex-direction: column;

      @media only screen and (max-width: 768px) {
        span {
          display: none
        }
      }

      button.LoginAction {
        background: none;
        border: none;
        text-align: right;
        font-size: 0.8em;
        font-weight: bold;
        padding: 0;
        color: $secondary_colour;

        &:hover {
          color: $primary_colour;
          cursor: pointer;
        }
      }
    }
  }
}

// Colours

$primary_colour: #8dc83c;
$secondary_colour: #20abe2;
$tertiary_colour: #233592;

$offWhite: #f9f9f9;
$lightGrey: #f1f2f6;
$midGrey: #a2a4a7;
$darkGrey: #dfe4ea;
$offBlack: #2f3640;
$white: #fff;

$green: #2ecc71;
$darkGreen: #27a85d;
$red: #e74c3c;
$darkRed: #bb3a2c;
$orange: #f39c12;
$darkOrange: #db8e14;
$blue: #3498db;
$darkBlue: #2b82bd;
$yellow: #f1c40f;
$darkYellow: #d6b11c;

$boxShadow: 0 5px 10px rgba(0,0,0,0.05), 0 3px 3px rgba(0,0,0,0.05);

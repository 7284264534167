@import '/theme';

.ActivityContainer {
  h1 {
    color: $tertiary_colour;
  }

  .ActivityStatContainer {
    display: flex;

    .ActivityStat {
      display: flex;
      flex-direction: row;
      align-items: center;

      background: $white;
      min-width: 110px;
      margin-right: 20px;
      text-align: center;

      border: 1px solid $darkGrey;
      border-radius: 5px;
      box-shadow: $boxShadow;

      h1 {
        background: $primary_colour;
        color: $white;
        font-size: 30px;
        font-weight: 500;
        padding: 10px 15px;
        margin-bottom: 0;
        border-radius: 5px 0 0 5px;
      }

      div {
        flex-grow: 2;
        color: $offBlack;
        text-align: center;
        padding: 5px 10px;
      }
    }
  }
}

@import '/theme';

.DataFilterContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 1em 0;

  .DataFilterHeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1em 1em;
    border-bottom: 1px solid $darkGrey;

    h1 {
      color: $tertiary_colour;
      margin: 0;
    }

    button.ui.button {
      background: none !important;
      padding: 0 !important;

      color: $secondary_colour !important;
      font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif !important;
      font-weight: 800;
      font-size: 16px;

      &:hover {
        color: $primary_colour !important;
        cursor: pointer;
      }
    }
  }

  .form {
    flex-grow: 2;
    overflow-y: auto;
    padding: 1em;

    .FormFieldTitleContainer {
      span {
        display: none;
      }
    }
  }

  .ButtonContainer {
    display: flex;
    justify-content: flex-end;

    border-top: 1px solid $darkGrey;
    padding: 1em 1em 0;

    button {
      margin: 0 0 0 0.75em;
    }
  }
}

@import url('//cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css');
@import '../../node_modules/react-big-calendar/lib/sass/styles';
@import '/theme';
@import '/navbar';
@import '/form';
@import '/table';
@import '/filter';
@import '/activity';
@import '/modal';

body {
  background: $offWhite;
  margin: 0;
  font-family: 'Lato', 'Nunito Sans', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  font-weight: 800;
}

h1 {
  font-size: 16px;
}

h2 {
  font-size: 16px;
}

h3 {
  font-size: 14px;
}

h4 {
  font-size: 14px;
}

h5 {
  font-size: 14px;
}

.RedText {
  color: $red !important;
}

.primaryHeader {
  text-transform: uppercase;
  font-size: 20px;
  color: $tertiary_colour;
}

button.button {
  background: $secondary_colour !important;
  color: $white !important;
  padding: 0.75rem 2rem !important;

  &:hover {
    background: $primary_colour !important;
  }
}

button.redButton {
  background: $red !important;

  &:hover {
    background: $darkRed !important;
  }
}

button.greenButton {
  background: $green !important;

  &:hover {
    background: $darkGreen !important;
  }
}

button.blueButton {
  background: $blue !important;

  &:hover {
    background: $darkBlue !important;
  }
}

button.orangeButton {
  background: $orange !important;

  &:hover {
    background: $darkOrange !important;
  }
}

.box {
  background: $white;
  box-shadow: $boxShadow;
  border: 1px solid $darkGrey;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}

.import-box {

  .import-title {
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
      padding: 12px 14px !important;
    }

    h1 {
      flex-grow: 2;
      margin: 0;
    }
  }

  form {
    display: flex;

    div {
      margin-right: 10px;

    }
  }
}

.App {
  height: 100vh;

  main.MainContainer {
    display: grid;
    grid-template-rows: 70px 1fr 40px;
    height: 100vh;

    .NavBar {
      background: $white;
      z-index: 2;
      position: fixed;
      width: 100%;
      grid-row: 1;
    }

    .Page {
      grid-row: 2;

      display: grid;
      grid-template-columns: minmax(0, 1fr) 300px;
      grid-template-rows: minmax(0px, calc(100vh - 110px));

      height: calc(100vh - 110px);

      main {
        grid-column: 1;
        grid-row: 1;
        padding: 1em;

        & > .box {
          &:last-of-type {
            display: flex;
            height: 100%;
            margin-bottom: 0;
            padding: 0;

            &.HomeTableHeight {
              height: calc(100% - 115px);
            }

            &.UploadTableHeightOpen {
              height: calc(100% - 209px);
            }

            &.UploadTableHeightClosed {
              height: calc(100% - 100px);
            }

            .StyledDataTable {
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              flex: 1;
              padding: 1em 0 0;

              .DataTableHeader {
                padding: 0 1em;
              }

              .DataTableDescription {
                padding: 0 1em 1.5em;
                margin-bottom: 0;
                border-bottom: 1px solid $darkGrey;
              }

              .DataTablePagination {
                padding: 1em;
                border-top: 1px solid $darkGrey;
              }

              .DataTableDataContainer {
                flex-grow: 2;
                overflow-y: auto;
                padding: 0.5em 1em;
              }
            }
          }
        }
      }

      aside {
        grid-column: 2;
        grid-row: 1;

        padding: 1em 1em 1em 0;

        & > .box {
          display: flex;
          height: 100%;
          margin-bottom: 0;
          padding: 0;
        }
      }

      @media only screen and (max-width: 1024px) {
        main {
          grid-column: 1 / 3;
        }

        aside {
          display: none;
        }

        .HideFilterButton {
          display: none;
        }
      }
    }

    footer {
      grid-row: 3;

      display: flex;
      align-items: center;
      justify-content: space-between;

      background: $white;
      border-top: 1px solid $darkGrey;
      padding: 0.5em 1.5em;

      font-size: 12px;
      color: $midGrey;

      .FooterVersion {

        p {
          display: flex;
          align-items: center;

          .FooterVersionStatusIndicator {
            display: flex;
            background: $lightGrey;
            width: 10px;
            height: 10px;
            border-radius: 20px;
            margin: 0 0.5em;

            &.red {
              background: $red;
            }

            &.green {
              background: $green;
            }
          }
        }

      }

    }
  }
}
